import React from "react";

interface Props {
  variant?: "primary" | "secondary";
  onClick?: () => void;
}

const Arrow = ({ variant = "primary", onClick }: Props) => {
  const fill = variant === "primary" ? "#1C3557" : "#FFFFFF";
  const stroke = variant === "primary" ? "#F4F8F8" : "#1C3557";

  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{ cursor: onClick ? "pointer" : "default" }}
    >
      <path
        d="M0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30Z"
        fill={fill}
      />
      <path
        d="M23.0555 36.9446L36.9444 23.0557"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.0555 23.0557H36.9444V36.9446"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Arrow;
