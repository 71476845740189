import React from "react";
import { useTranslation } from "react-i18next";
import Arrow from "../../assets/icons/Arrow";
import styled from "styled-components";
import Button from "../../components/button/Button";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  margin-left: calc(-50vw + 50%);
  width: 100vw;
  background-color: #e8ecf0;
`;

const HeroWrapper = styled.section`
  margin-bottom: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  text-align: center;
  min-height: 100vh;

  @media screen and (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
    /* min-width: 300px; */
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const HeroContent = styled.div`
  max-width: 730px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeroTitle = styled.h2<{ isSwedish: boolean }>`
  font-size: 64px;
  line-height: 72px;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 1rem;
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;

  @media screen and (max-width: 768px) {
    ${({ isSwedish }) =>
      isSwedish &&
      `
    word-break: break-all;
    hyphens: auto;
  `}
  }
`;

const HeroParagraph = styled.p`
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 2rem;
  max-width: 100%;
  overflow-wrap: break-word;
`;

const HeroSection: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const isSwedish = i18n.language === "sv";

  return (
    <Container>
      <HeroWrapper>
        <HeroContent>
          <HeroTitle isSwedish={isSwedish}>{t("hero")}</HeroTitle>
          <HeroParagraph>{t("hero_paragraph")}</HeroParagraph>
          <div>
            <Button
              icon={<Arrow variant="secondary" />}
              onClick={() => navigate("/contact-us")}
            >
              {t("join_waiting_list")}
            </Button>
          </div>
        </HeroContent>
      </HeroWrapper>
    </Container>
  );
};

export default HeroSection;
