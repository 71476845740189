import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const InputField = styled.input`
  width: 387px;
  height: 60px;
  background: #f4f8f8;
  border: 1px solid #467c9d;
  border-radius: 36px;
  padding: 0 20px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #467c9d;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: black;
    background-color: #fff;
    color: black;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const Label = styled.label`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #467c9d;
  margin-bottom: 10px;
`;
