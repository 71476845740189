import React from "react";
import styled from "styled-components";
import Button from "../../components/button/Button";
import Arrow from "../../assets/icons/Arrow";
import { useNavigate } from "react-router-dom";

const titleColor = "#1C3557";
const paragraphColor = "#556E7D";

const SectionContainer = styled.div`
  border-radius: 20px;
  background-color: #e8ecf0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 50vh;
  width: 100%;
  margin-bottom: 2rem;
  margin-top: 10rem;
`;

const SectionContent = styled.div`
  padding-bottom: 3rem;
  padding-top: 5rem;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
`;

const Title = styled.h1`
  font-size: 64px;
  line-height: 72px;
  color: ${titleColor};
  @media screen and (max-width: 768px) {
    font-size: 36px;
    line-height: 38px;
  }
`;

const Paragraph = styled.p`
  font-size: 24px;
  line-height: auto;
  color: ${paragraphColor};
  margin-bottom: 20px;
  margin-bottom: 6rem;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

interface SectionProps {
  title: string;
  paragraph: string;
  buttonText: string;
}

const JoinNowSection: React.FC<SectionProps> = ({
  title,
  paragraph,
  buttonText,
}) => {
  const navigate = useNavigate();
  return (
    <SectionContainer>
      <SectionContent>
        <Title>{title}</Title>
        <Paragraph>{paragraph}</Paragraph>
        <div>
          <Button
            icon={
              <Arrow
                variant="secondary"
                onClick={() => navigate("/contact-us")}
              />
            }
            onClick={() => navigate("/contact-us")}
          >
            {buttonText}
          </Button>
        </div>
      </SectionContent>
    </SectionContainer>
  );
};

export default JoinNowSection;
