import { useTranslation } from "react-i18next";
import Section from "../../components/section/Section";
import HeroSection from "./HeroSection";

import all_in_one from "../../assets/all_in_one.png";
import gain_control from "../../assets/gain_control.png";
import scale_ups from "../../assets/scale_ups.png";
import security from "../../assets/security.png";
import integration from "../../assets/integration.png";
import JoinNowSection from "./JoinSection";
import Tag from "../../components/tag/Tag";
import CardList from "../../components/card/CardList";

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeroSection />
      <section id="all_in_one">
        <Section
          imageSrc={all_in_one}
          title={t("all_in_one")}
          paragraph={t("all_in_one_paragraph")}
          imagePosition="bottom"
          childrenPosition="bottom"
        />
      </section>
      <section id="gain_control">
        <Section
          imageSrc={gain_control}
          title={t("gain_control")}
          paragraph={t("gain_control_paragraph")}
          imagePosition="left"
          children={<Tag text={t("all_in_one_tag")} />}
        />
      </section>
      <section id="features">
        <CardList
          tagText={t("features_tag")}
          paragraphText={t("features_paragraph")}
        />
      </section>
      <section id="from_scale_ups">
        <Section
          imageSrc={scale_ups}
          title={t("from_scale_ups")}
          paragraph={t("form_scale_ups_paragraph")}
          imagePosition="right"
          children={<Tag text={t("for_finance_experts")} />}
          childrenPosition="top"
        />
      </section>
      <section id="security">
        <Section
          imageSrc={security}
          title={t("security")}
          paragraph={t("security_paragraph")}
          imagePosition="left"
          children={<Tag text={t("security_tag")} />}
          background
        />
      </section>
      <section id="integration">
        <Section
          imageSrc={integration}
          title={t("integration")}
          paragraph={t("integration_paragraph")}
          imagePosition="right"
          children={<Tag text={t("integration_tag")} />}
          childrenPosition="top"
          contact
        />
      </section>
      <section id="join_now">
        <JoinNowSection
          buttonText={t("join_now")}
          title={t("secure_your_place")}
          paragraph={t("secure_your_place_paragraph")}
        />
      </section>
    </>
  );
};

export default Home;
