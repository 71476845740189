import styled from "styled-components";

export const HeaderContainer = styled.header<{ blur: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  height: 10px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding-left: calc((100vw - 1440px) / 2 + 114px);
  padding-right: calc((100vw - 1440px) / 2 + 114px);
  padding-top: 3rem;
  padding-bottom: 2rem;
  transition: backdrop-filter 0.3s;
  backdrop-filter: ${({ blur }) => (blur ? "blur(5px)" : "none")};
  padding-bottom: 2rem;
  background-color: ${({ blur }) =>
    blur ? "rgba(232, 236, 240, 0.5)" : "transparent"};

  @media screen and (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
    /* min-width: 300px; */
  }
`;

export const Logo = styled.div`
  height: 40px;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    width: 36px;
  }
  @media screen and (max-width: 768px) {
    svg {
      width: 120px;
    }
  }
`;

export const ButtonContainer = styled.div`
  margin-left: auto;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  @media (max-width: 768px) {
    gap: 0px;
  }
`;

export const SelectWrapper = styled.div`
  select {
    text-transform: uppercase;
    background-color: transparent;
    color: #1c3557;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 8px;
    border-radius: 0px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  @media (max-width: 768px) {
    select {
      font-size: 12px;
    }
  }
`;
