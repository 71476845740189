import React from "react";
import * as S from "./style";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  width?: string;
  height?: string;
}

const InputComponent: React.FC<InputProps> = ({ label, ...rest }) => {
  return (
    <S.Container>
      {label && <S.Label>{label}</S.Label>}
      <S.InputField {...rest} />
    </S.Container>
  );
};

export default InputComponent;
