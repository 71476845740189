import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem;
  padding-top: 6rem;
  padding-left: 0px;
  flex-direction: column;
  padding-right: 0px;
  gap: 2rem;
  min-height: 80vh;
  gap: 0;
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 10rem;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;

    justify-content: center;
    align-items: center;
  }
`;

export const ContentContainer = styled.div`
  flex: 1;
`;

export const FormContainer = styled.div`
  flex: 1;
  max-width: 400px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const Button = styled.button`
  padding: 10px 20px;
  background-color: #1c3557;
  color: #fff;
  border: none;
  cursor: pointer;
  align-self: flex-end;
`;

export const Heading = styled.h1`
  font-size: 64px;
  color: #1c3557;
  line-height: 72px;
  @media screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 46px;
  }
`;

export const Paragraph = styled.p`
  font-size: 24px;
  color: #556e7d;
  line-height: 32px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const InputField = styled.input`
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
`;

export const Label = styled.label`
  margin-bottom: 8px;
  margin-left: 14px;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 1.5rem;
`;

export const CaptchaContainer = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: center;
  width: 100%;
`;
