import styled from "styled-components";

const tagColor = "#CDDEEA";
const textColor = "#467C9D";

export const TagContainer = styled.div`
  border-radius: 36px;
  background-color: ${tagColor};
  padding: 8px 16px;
  min-width: 167px;
  text-align: center;
`;

export const TagText = styled.span`
  font-weight: 600;
  color: ${textColor};
`;
