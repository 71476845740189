import React, { ReactNode } from "react";
import styled, { ThemeProvider } from "styled-components";
import { ThemeType } from "./theme";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import ScrollToTop from "../../components/scrollTop/ScrollTop";

const LayoutContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fonts.body};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Container = styled.div`
  padding-left: calc((100vw - 1440px) / 2 + 114px);
  padding-right: calc((100vw - 1440px) / 2 + 114px);
  flex: 1;

  @media screen and (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
    /* min-width: 300px; */
  }
`;

interface LayoutProps {
  theme: ThemeType;
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children, theme }) => {
  return (
    <ThemeProvider theme={theme}>
      <LayoutContainer>
        <Header />
        <Container>
          <ScrollToTop />
          <>{children}</>
        </Container>
        <Footer />
      </LayoutContainer>
    </ThemeProvider>
  );
};

export default Layout;
