import React from "react";
import { ButtonText, StyledButton } from "./style";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary";
  icon?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  variant = "primary",
  icon,
  children,
  onClick,
}) => {
  return (
    <StyledButton variant={variant} onClick={onClick}>
      <ButtonText>{children}</ButtonText>
      {icon && <>{icon}</>}
    </StyledButton>
  );
};

export default Button;
