import styled from "styled-components";

export const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.primaryLight};
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const Logo = styled.div`
  height: 40px;
`;

export const SocialMediaLinks = styled.div`
  display: flex;
  gap: 20px;
`;

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  max-width: 1200px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const TextContainer = styled.div`
  flex: 1;
  width: 250px;
  @media (max-width: 767px) {
    width: 95%;
    margin-bottom: 10px;
    text-align: center;
  }
`;

export const ActionContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-self: end;

  @media (max-width: 768px) {
    justify-content: flex-end;

    align-self: center;
    margin-left: 0px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 36px;
`;

export const Paragraph = styled.p`
  margin-bottom: 10px;
  font-size: 18px;
`;

export const BottomSection = styled.div`
  margin-top: auto;
  width: 100%;
  max-width: 1200px;
  display: flex;
  margin-top: 4rem;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    gap: 20px;
    margin-top: 2rem;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 767px) {
    margin-top: 10px;
    align-items: center;
    font-size: 12px;
  }
`;

export const FooterLink = styled.a`
  text-decoration: none;
  color: white;
`;

export const Flex = styled.div`
  @media (max-width: 767px) {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-direction: column;
    align-items: center;
  }
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    gap: 60px;
  }
`;

export const InfoSection = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  align-self: end;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: row;
    gap: 14px;
    align-self: auto;
  }
`;
