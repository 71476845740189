const theme = {
  colors: {
    background: "#FBFFFF",
    primary: "#1C3557",
    primaryLight: "#467C9D",
    secondary: "#f0f0f0",
    light: "#2c303a",
    link: "#FFFFFF",
    text: "#556E7D",
    gray: "#E8ECF0",
  },
  fonts: {
    body: "Inter, sans-serif",
    heading: "Montserrat, sans-serif",
  },
  typography: {
    body: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "24px",
      lineHeight: "32px",
    },
    headings: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "56px",
    },
  },
  spacing: {
    xs: "4px",
    sm: "8px",
    md: "16px",
    lg: "24px",
    xl: "32px",
  },
  borderRadius: {
    sm: "4px",
    md: "8px",
    lg: "12px",
    xl: "36px",
    xxl: "72px",
  },
};

export type ThemeType = typeof theme;
export default theme;
