import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en.json";
import translationSV from "./locales/sv.json";

i18n
  .use(initReactI18next)
  .use(LanguageDetector) // Add the language detector
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      sv: {
        translation: translationSV,
      },
    },
    fallbackLng: "en",
    detection: {
      order: [
        "navigator",
        "htmlTag",
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
      ],
      caches: ["localStorage", "cookie"],
    },
    interpolation: {
      escapeValue: false, // not needed for React as it escapes by default
    },
  });

export default i18n;
