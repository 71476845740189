import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import routes from "./utils/routes";
import Layout from "./utils/theme/Layout";
import theme from "./utils/theme/theme";

function App() {
  return (
    <div className="App">
      <Router>
        <Layout theme={theme}>
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} {...route} />
            ))}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
