import HCaptcha from "@hcaptcha/react-hcaptcha";
import React, { useRef, useState } from "react";
import Input from "../../components/input/Input";

import { useFormspark } from "@formspark/use-formspark";
import { useTranslation } from "react-i18next";
import Arrow from "../../assets/icons/Arrow";
import Button from "../../components/button/Button";
import {
  ActionContainer,
  CaptchaContainer,
  Container,
  ContentContainer,
  Form,
  FormContainer,
  Heading,
  InputContainer,
  Label,
  Paragraph,
} from "./style";

const ContactUs: React.FC = () => {
  const { t } = useTranslation();
  const [hCaptchaToken, setHCaptchaToken] = useState("");
  const captchaRef = useRef(null);
  const [submit] = useFormspark({
    formId: process.env.REACT_APP_FORMSPARK_ID as string,
  });

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    telephone: "",
    comment: "",
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { fullname, email, telephone, comment } = formData;
    if (!fullname || !email || !hCaptchaToken) {
      console.error("Please fill in all required fields including hCaptcha.");
      return;
    }

    try {
      await submit({
        name: fullname,
        email: email,
        telephone: telephone,
        comment: comment,
        "h-captcha-response": hCaptchaToken,
      });
      alert("Answer submitted!");
      setFormData({
        fullname: "",
        email: "",
        telephone: "",
        comment: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const onHCaptchaVerify = (token: string) => {
    setHCaptchaToken(token);
  };

  return (
    <section>
      <Container>
        <ContentContainer>
          <Heading>{t("contact_us")}</Heading>
          <Paragraph>{t("contact_us_paragraph")}</Paragraph>
          <Paragraph>{t("contact_us_paragraph_second")}</Paragraph>
        </ContentContainer>
        <FormContainer>
          <Form onSubmit={handleSubmit}>
            <InputContainer>
              <Label htmlFor="fullname">{t("full_name")}</Label>
              <Input
                type="text"
                id="fullname"
                name="fullname"
                value={formData.fullname}
                onChange={handleInputChange}
                required
                placeholder={t("enter_name")}
              />
            </InputContainer>
            <InputContainer>
              <Label htmlFor="email">{t("email")}</Label>
              <Input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                placeholder={t("enter_email")}
              />
            </InputContainer>
            <InputContainer>
              <Label htmlFor="telephone">{t("telephone")}</Label>
              <Input
                type="tel"
                id="telephone"
                name="telephone"
                value={formData.telephone}
                onChange={handleInputChange}
                placeholder={t("enter_telephone")}
              />
            </InputContainer>
            <InputContainer>
              <Label htmlFor="comment">{t("comment")}</Label>
              <Input
                type="text"
                id="comment"
                name="comment"
                value={formData.comment}
                onChange={handleInputChange}
                placeholder={t("enter_comment")}
              />
            </InputContainer>
            <InputContainer>
              <CaptchaContainer>
                <HCaptcha
                  sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY as string}
                  onVerify={onHCaptchaVerify}
                  ref={captchaRef}
                />
              </CaptchaContainer>
            </InputContainer>
            <ActionContainer>
              <Button type="submit" icon={<Arrow variant="secondary" />}>
                {t("contact_us")}
              </Button>
            </ActionContainer>
          </Form>
        </FormContainer>
      </Container>
    </section>
  );
};

export default ContactUs;
