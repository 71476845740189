import React from "react";
import { TagContainer, TagText } from "./style";

interface TagProps {
  text: string;
}

const Tag: React.FC<TagProps> = ({ text }) => {
  return (
    <TagContainer>
      <TagText>{text}</TagText>
    </TagContainer>
  );
};

export default Tag;
