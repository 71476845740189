import { useEffect, useState } from "react";
import styled from "styled-components";
import Up from "../../assets/icons/Up";

const ScrollToTopButton = styled.button<{ isVisible: boolean }>`
  position: fixed;
  bottom: 50px;
  right: 50px;
  background-color: transparent;
  color: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? "1" : "0")};
  pointer-events: ${({ isVisible }) => (isVisible ? "auto" : "none")};
  z-index: 100;
`;

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <ScrollToTopButton isVisible={isVisible} onClick={scrollToTop}>
      <Up onClick={scrollToTop} />
    </ScrollToTopButton>
  );
};

export default ScrollToTop;
