import styled, { css } from "styled-components";

const textColor = "#556E7D";
const titleColor = "#1C3557";

export const SectionContainer = styled.div<{ imagePosition: string }>`
  width: 100%;
  display: flex;
  flex-direction: ${({ imagePosition }) =>
    imagePosition === "bottom" ? "column" : "row"};
  align-items: ${({ imagePosition }) =>
    imagePosition === "bottom" ? "center" : "center"};
  text-align: ${({ imagePosition }) =>
    imagePosition === "bottom" ? "center" : "left"};
  max-width: ${({ imagePosition }) =>
    imagePosition === "bottom" ? "75%" : "none"};
  margin: ${({ imagePosition }) => (imagePosition === "bottom" ? "auto" : "0")};
  min-height: 80vh;
  justify-content: ${({ imagePosition }) =>
    imagePosition === "bottom" ? "center" : "space-between"};
  margin-top: ${({ imagePosition }) =>
    imagePosition === "top" ? "5rem" : "0"};

  ${({ imagePosition }) =>
    imagePosition === "left" &&
    css`
      flex-direction: row-reverse;
    `}

  flex-grow: 1;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    margin-bottom: 2rem;
    min-height: 60vh;
  }
`;

export const SectionContent = styled.div`
  max-width: 590px;
  flex: 1;
`;

export const Title = styled.h3`
  font-size: 48px;
  line-height: 56px;
  font-weight: bold;
  color: ${titleColor};
  @media screen and (max-width: 768px) {
    font-size: 36px;
    line-height: 38px;
  }
`;

export const Paragraph = styled.p<{ imagePosition: string }>`
  font-size: 24px;
  line-height: 32px;
  color: ${textColor};
  margin-bottom: ${({ imagePosition }) =>
    imagePosition === "bottom" ? "5rem" : "auto"};

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 1rem;
  }
`;

export const ImageContainer = styled.div`
  flex: 0 0 auto;
`;

export const SectionWrapper = styled.div<{ background: boolean }>`
  margin-left: calc(-50vw + 50%);
  width: 100vw;
  background-color: ${({ background }) => (background ? "#E8ECF0" : "none")};
`;

export const ChildrenWrapper = styled.div<{ childrenPosition: string }>`
  width: 100%;
  margin-bottom: ${({ childrenPosition }) =>
    childrenPosition === "bottom" ? "0" : "2rem"};
  padding-top: 3rem;
  display: flex;
  justify-content: center;
  align-self: center;
`;

export const StyledImage = styled.img`
  @media (max-width: 768px) {
    max-width: 100%;
    height: auto;
  }
`;

export const ContentWrapper = styled.div`
  padding-left: calc((100vw - 1440px) / 2 + 114px);
  padding-right: calc((100vw - 1440px) / 2 + 114px);
  flex: 1;

  @media screen and (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
    /* min-width: 300px;/ */
  }
`;

export const Contact = styled.div`
  text-align: right;
  color: #467c9d;
  font-size: 14px;
  line-height: 44px;
  display: flex;
  justify-content: end;
  gap: 4px;
  margin-top: 1rem;
`;

export const StyledSpan = styled.div`
  color: #467c9d;
  font-size: 14px;
  line-height: 44px;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const Line = styled.hr`
  width: 100%;
  height: 0.5px;
  border: none;
  background-color: #467c9d;
  position: relative;
  bottom: 0.8rem;

  @media screen and (max-width: 768px) {
    bottom: 2.25rem;
  }

  @media screen and (max-width: 1024px) {
    bottom: 2.2rem;
  }
`;
