import styled from "styled-components";

const textColor = "#556E7D";
const titleColor = "#1C3557";

export const CardContainer = styled.div`
  text-align: left;
  max-width: 590px;
`;

export const Image = styled.img`
  max-width: 590px;
  max-height: 421px;
  width: 100%;
  height: auto;
`;

export const Title = styled.h2`
  font-size: 36px;
  line-height: 48px;
  color: ${titleColor};
  @media screen and (max-width: 768px) {
    font-size: 36px;
    line-height: 38px;
  }
`;

export const Paragraph = styled.p`
  font-size: 24px;
  line-height: 32px;
  color: ${textColor};
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

export const CardListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;

  min-height: 100vh;
  flex-grow: 1;
  margin-bottom: 5rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    align-items: center;
  }
`;

export const TopParagraph = styled.p`
  font-size: 24px;
  line-height: 32px;
  color: ${textColor};
  max-width: 480px;
  text-align: center;
`;

export const CardListTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;
`;

export const TagWrapper = styled.div`
  margin-top: 2rem;
`;
