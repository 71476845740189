import React from "react";

interface Props {
  onClick?: () => void;
}

const LogoLight = ({ onClick }: Props) => {
  return (
    <svg
      width="160"
      height="38"
      viewBox="0 0 160 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00074 0.678345V17.6885C6.00074 19.5332 7.49617 21.0287 9.34088 21.0287H20.8721V26.7685H8.99269C4.02617 26.7685 0 22.7424 0 17.7758V0.678345H6.00074Z"
        fill="#F4F8F8"
      />
      <path
        d="M41.4569 14.6627V0.678345H47.0924V26.7685H41.4569V23.429C39.7175 26.1076 37.0215 27.4469 33.3689 27.4469C30.3425 27.4469 27.9074 26.4902 26.0637 24.5769C24.2548 22.6289 23.3503 20.0025 23.3503 16.6977V0.678345H28.9858V16.1237C28.9858 18.0718 29.5076 19.5676 30.5512 20.6112C31.5948 21.6548 33.0211 22.1766 34.83 22.1766C36.8824 22.1766 38.5 21.5505 39.6827 20.2982C40.8655 19.011 41.4569 17.1325 41.4569 14.6627Z"
        fill="#F4F8F8"
      />
      <path
        d="M81.3899 0C84.3468 0 86.7297 0.956639 88.5386 2.86992C90.3475 4.7832 91.252 7.35743 91.252 10.5926V26.7685H85.6165V10.9579C85.6165 9.18374 85.1643 7.79226 84.2598 6.78344C83.3554 5.77462 82.1204 5.27021 80.555 5.27021C78.7809 5.27021 77.3894 5.86159 76.3806 7.04434C75.3718 8.19231 74.8674 9.89687 74.8674 12.158V26.7685H69.2319V10.9579C69.2319 9.14895 68.7971 7.75748 67.9274 6.78344C67.0925 5.77462 65.8923 5.27021 64.3269 5.27021C62.5876 5.27021 61.1787 5.86159 60.1003 7.04434C59.0567 8.2271 58.5349 9.93166 58.5349 12.158V26.7685H52.8994V0.678345H58.5349V3.80916C60.2047 1.26972 62.692 0 65.9967 0C69.3363 0 71.8061 1.37408 73.4063 4.12225C75.1109 1.37408 77.7721 0 81.3899 0Z"
        fill="#F4F8F8"
      />
      <path
        d="M102.136 26.7685H96.5006V0.678345H102.136V26.7685Z"
        fill="#F4F8F8"
      />
      <path
        d="M121.62 0C124.647 0 127.064 0.974033 128.873 2.9221C130.717 4.83538 131.639 7.4444 131.639 10.7492V26.7685H126.003V11.3231C126.003 9.37507 125.481 7.87923 124.438 6.83562C123.394 5.79202 121.968 5.27021 120.159 5.27021C118.107 5.27021 116.489 5.91377 115.306 7.20089C114.124 8.45321 113.532 10.3143 113.532 12.7842V26.7685H107.897V0.678345H113.532V4.01789C115.272 1.3393 117.968 0 121.62 0Z"
        fill="#F4F8F8"
      />
      <path
        d="M147.32 19.6198L153.999 0.678345H160L149.981 27.6034C147.477 34.3521 143.233 37.5525 137.249 37.2046V31.9344C138.989 32.0387 140.398 31.6735 141.476 30.8386C142.554 30.0037 143.459 28.6644 144.189 26.8207L144.45 26.2989L133.388 0.678345H139.545L147.32 19.6198Z"
        fill="#F4F8F8"
      />
    </svg>
  );
};

export default LogoLight;
