import React from "react";
import Tag from "../tag/Tag";
import Card from "./Card";
import {
  CardListContainer,
  CardListTop,
  TagWrapper,
  TopParagraph,
} from "./style";
import { useTranslation } from "react-i18next";

// images
import automate from "../../assets/cards/automate.png";
import collaborate from "../../assets/cards/collaborate.png";
import insights from "../../assets/cards/insights.png";
import predict from "../../assets/cards/predict.png";

interface CardListProps {
  tagText: string;
  paragraphText: string;
}

const CardList: React.FC<CardListProps> = ({ tagText, paragraphText }) => {
  const { t } = useTranslation();
  const cards = [
    {
      imageUrl: automate,
      title: t("features_1"),
      paragraph: t("features_1_paragraph"),
    },
    {
      imageUrl: insights,
      title: t("features_2"),
      paragraph: t("features_2_paragraph"),
    },
    {
      imageUrl: collaborate,
      title: t("features_3"),
      paragraph: t("features_3_paragraph"),
    },
    {
      imageUrl: predict,
      title: t("features_4"),
      paragraph: t("features_4_paragraph"),
    },
  ];
  return (
    <>
      <CardListTop>
        <TagWrapper>
          <Tag text={tagText} />
        </TagWrapper>
        <div>
          <TopParagraph>{paragraphText}</TopParagraph>
        </div>
      </CardListTop>
      <CardListContainer>
        {cards.map((card, index) => (
          <Card
            key={index}
            imageUrl={card.imageUrl}
            title={card.title}
            paragraph={card.paragraph}
          />
        ))}
      </CardListContainer>
    </>
  );
};

export default CardList;
