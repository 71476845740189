import React from "react";
import { CardContainer, Image, Title, Paragraph } from "./style";

interface CardProps {
  imageUrl: string;
  title: string;
  paragraph: string;
}

const Card: React.FC<CardProps> = ({ imageUrl, title, paragraph }) => {
  return (
    <CardContainer>
      <Image src={imageUrl} alt={title} loading="lazy" />
      <Title>{title}</Title>
      <Paragraph>{paragraph}</Paragraph>
    </CardContainer>
  );
};

export default Card;
