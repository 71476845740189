import React from "react";

interface Props {
  onClick?: () => void;
}

const Up = ({ onClick }: Props) => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="64"
      height="64"
      fill="#467c9d"
      cursor={"pointer"}
      onClick={onClick}
    >
      <circle cx="30" cy="30" r="30" fill="#467c9d" />
      <path
        d="M29.57,39.82v-19.64"
        fill="none"
        stroke="#f4f8f8"
        strokeWidth="3px"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.75,30l9.82-9.82,9.82,9.82"
        fill="none"
        stroke="#f4f8f8"
        strokeWidth="3px"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Up;
