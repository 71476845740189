import { FC } from "react";
import Home from "../pages/home/Home";
import ContactUs from "../pages/contact-us/ContactUs";

interface IRoutesArray {
  name: string;
  path: string;
  Component: FC;
}

const routes: IRoutesArray[] = [
  {
    name: "Home",
    path: "",
    Component: Home,
  },
  {
    name: "Contact us",
    path: "/contact-us",
    Component: ContactUs,
  },
];

export default routes;
