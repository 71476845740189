import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import LogoDark from "../../assets/icons/Logo";
import Button from "../button/Button";
import {
  ActionsContainer,
  ButtonContainer,
  HeaderContainer,
  Logo,
  SelectWrapper,
} from "./style";

const Header: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    setIsScrolled(scrollTop > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <HeaderContainer blur={isScrolled}>
      <Logo onClick={() => navigate("/")}>
        <LogoDark />
      </Logo>

      <ActionsContainer>
        <SelectWrapper>
          <select onChange={changeLanguage}>
            <option value="en">EN</option>
            <option value="sv">SWE</option>
          </select>
        </SelectWrapper>
        {location.pathname !== "/contact-us" && (
          <ButtonContainer>
            <Button variant="secondary" onClick={() => navigate("/contact-us")}>
              {t("request_demo")}
            </Button>
          </ButtonContainer>
        )}
      </ActionsContainer>
    </HeaderContainer>
  );
};

export default Header;
