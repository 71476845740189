import React from "react";

const FacebookIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36 18C36 8.05888 27.9411 0 18 0C8.05887 0 0 8.05888 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18Z"
        fill="#F4F8F8"
      />
      <path
        d="M15.2568 28.8772H19.6362V17.905H22.6914L23.013 14.235H19.6267V12.1447C19.6267 11.2745 19.797 10.9339 20.6388 10.9339H23.013V7.12207H19.9767C16.7134 7.12207 15.2473 8.5598 15.2473 11.3028V14.235H12.9678V17.9523H15.2473V28.8772H15.2568Z"
        fill="#467C9D"
      />
    </svg>
  );
};

export default FacebookIcon;
