import React from "react";

const TwitterIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_99_2260)">
        <path
          d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z"
          fill="#F4F8F8"
        />
        <path
          d="M6.91691 7.57324L15.5183 19.0804L6.86548 28.4275H8.81976L16.4055 20.2375L22.5255 28.4275H29.1598L20.0698 16.2775L28.1312 7.57324H26.1769L19.1955 15.1204L13.5512 7.57324H6.91691ZM9.78405 9.01324H12.8312L26.2798 27.0004H23.2326L9.77119 9.01324H9.78405Z"
          fill="#467C9D"
        />
      </g>
      <defs>
        <clipPath id="clip0_99_2260">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TwitterIcon;
