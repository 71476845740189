import styled from "styled-components";
import { ButtonProps } from "./Button";
export const StyledButton = styled.button<ButtonProps>`
  padding: ${({ variant, icon }) =>
    variant === "secondary" ? "18px 28px" : "6px 6px"};
  background: ${({ variant }) =>
    variant === "secondary" ? "transparent" : "#1C3557"};
  border: ${({ variant }) =>
    variant === "secondary" ? "1px solid #1C3557" : "none"};
  border-radius: 60px;
  color: ${({ variant }) => (variant === "secondary" ? "#1C3557" : "#fff")};
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ variant }) => (variant === "secondary" ? "18px" : "24px")};
  line-height: ${({ variant }) => (variant === "secondary" ? "20px" : "24px")};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: ${({ variant }) =>
      variant === "secondary" ? "none" : "#FDB614"};
  }

  @media (max-width: 767px) {
    padding: ${({ variant, icon }) =>
      variant === "secondary" ? "12px 12px" : "4px 4px"};
    font-size: 16px;
  }
`;

export const ButtonText = styled.span`
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: 767px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
