import React from "react";
import { useTranslation } from "react-i18next";

import Arrow from "../../assets/icons/Arrow";
import LogoLight from "../../assets/icons/LogoLight";
import FacebookIcon from "../../assets/svg/social/facebook";
import InstagramIcon from "../../assets/svg/social/instagram";
import TwitterIcon from "../../assets/svg/social/twitter";
import {
  ActionContainer,
  BottomSection,
  ContactWrapper,
  Flex,
  FooterContainer,
  FooterLink,
  FooterLinks,
  InfoSection,
  Logo,
  Paragraph,
  SocialMediaLinks,
  TextContainer,
  Title,
  TopSection,
} from "./style";
import Button from "../button/Button";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const footerLinks = [
    { label: t("privacy_policy"), url: "#" },
    { label: t("cookie_policy"), url: "#" },
    { label: t("tos"), url: "#" },
  ];

  return (
    <section>
      <FooterContainer>
        <TopSection>
          <div>
            <Logo>
              <LogoLight />
            </Logo>
          </div>
          <SocialMediaLinks>
            <InstagramIcon />
            <FacebookIcon />
            <TwitterIcon />
          </SocialMediaLinks>
        </TopSection>
        <ContactWrapper>
          <Flex>
            <TextContainer>
              <Title>{t("stay_in_touch")}</Title>
              <Paragraph>{t("stay_in_touch_paragraph")}</Paragraph>
            </TextContainer>
            <ActionContainer>
              <Button
                type="submit"
                icon={
                  <Arrow
                    variant="secondary"
                    onClick={() => navigate("/contact-us")}
                  />
                }
                onClick={() => navigate("/contact-us")}
              >
                {t("contact_us")}
              </Button>{" "}
            </ActionContainer>
          </Flex>
          <InfoSection>
            <Paragraph>info@luminy.se</Paragraph>
            <Paragraph>+46-708 50 40 45</Paragraph>
          </InfoSection>
        </ContactWrapper>
        <BottomSection>
          <div>
            <span>{t("rights_reserved")}</span>
          </div>
          <FooterLinks>
            {footerLinks.map((link, index) => (
              <FooterLink key={index} href={link.url}>
                {link.label}
              </FooterLink>
            ))}
          </FooterLinks>
        </BottomSection>
      </FooterContainer>
    </section>
  );
};

export default Footer;
