import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  ChildrenWrapper,
  Contact,
  ContentWrapper,
  ImageContainer,
  Line,
  Paragraph,
  SectionContainer,
  SectionContent,
  SectionWrapper,
  StyledImage,
  StyledSpan,
  Title,
} from "./style";

interface SectionProps {
  title: string;
  paragraph: string;
  imageSrc: string;
  imagePosition?: "left" | "right" | "bottom";
  childrenPosition?: "top" | "bottom";
  children?: React.ReactNode;
  background?: boolean;
  contact?: boolean;
}

const Section: React.FC<SectionProps> = ({
  title,
  paragraph,
  imageSrc,
  imagePosition = "right",
  children,
  childrenPosition = "top",
  background,
  contact,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <SectionWrapper background={background as boolean}>
      <ContentWrapper>
        {childrenPosition === "top" && children ? (
          <ChildrenWrapper childrenPosition={childrenPosition}>
            {children}
          </ChildrenWrapper>
        ) : null}
        <SectionContainer imagePosition={imagePosition}>
          <SectionContent>
            <Title>{title}</Title>
            <Paragraph imagePosition={imagePosition}>{paragraph}</Paragraph>
          </SectionContent>
          <ImageContainer>
            {imagePosition === "bottom" ? (
              <StyledImage src={imageSrc} alt="img" width={"100%"} />
            ) : (
              <StyledImage src={imageSrc} alt="img" height={412} />
            )}{" "}
            {contact ? (
              <Contact>
                {t("erp_missing")}{" "}
                <StyledSpan onClick={() => navigate("/contact-us")}>
                  {t("please_contact_us")}
                </StyledSpan>
              </Contact>
            ) : null}
          </ImageContainer>
        </SectionContainer>
      </ContentWrapper>
      {imagePosition === "bottom" ? <Line /> : null}
    </SectionWrapper>
  );
};

export default Section;
